<template>
  <div
    data-testId="color-item"
    class="color_with_label"
    @click.stop="updateColor()"
    @keyup.space.enter="updateColor()"
    @keydown.prevent.space
    @mouseenter.stop="onMouseEnter(color.title)"
    @mouseleave.stop="onMouseLeave()">
    <div
      :class="[
        'product_preview-variants-thumbnail',
        'color-circle',
        { pdp: isPdp },
        { selected: color.preselected },
        { 'product_preview-variants-thumbnail--disabled': !hideDisabledColorClass && color.not_available },
      ]">
      <span rel="nofollow">
        <img
          :src="color.thumbnail_url"
          :title="color.title"
          :alt="color.title"
          :data-variant-image-src="color.image"
          :data-color="color.title" />
      </span>
    </div>
    <div
      v-if="showColorTitle"
      class="label-color">
      <span rel="nofollow">
        <span>{{ color.title }}</span>
        <span
          v-if="color.coming_soon"
          class="coming-soon">
          {{ $t('product_detail.add_to_cart.proximamente') }}
        </span>
      </span>
    </div>
    <div
      v-if="colorZoomIsVisible"
      data-testId="zoomColor"
      class="color_item_zoom"
      :class="['product_preview-variants-thumbnail', 'color-circle']">
      <span>
        <img :src="color?.thumbnail_url" />
      </span>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
  name: 'ColorItem',
  props: {
    color: {
      type: Object,
      required: true,
    },
    isPdp: {
      type: Boolean,
      required: true,
    },
    showColorTitle: {
      type: Boolean,
      default: false,
    },
    hideDisabledColorClass: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateColor', 'loadSizes', 'sendColorName'],
  setup(props, { emit }) {
    const showColorZoom = inject('showColorZoom', false);
    const colorZoomIsVisible = ref(false);
    const updateColor = () => {
      emit('updateColor', props.color);
      emit('loadSizes');
    };
    const onMouseEnter = () => {
      sendColorName();
      if (showColorZoom) {
        colorZoomIsVisible.value = true;
      }
    };
    const onMouseLeave = () => {
      const clearSelectedColor = true;
      sendColorName(clearSelectedColor);
      if (showColorZoom) {
        colorZoomIsVisible.value = false;
      }
    };
    const sendColorName = (clearSelectedColor) => {
      emit('sendColorName', clearSelectedColor ? undefined : props.color?.title);
    };

    return {
      colorZoomIsVisible,
      onMouseEnter,
      onMouseLeave,
      showColorZoom,
      updateColor,
    };
  },
};
</script>

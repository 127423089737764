<template>
  <div
    v-if="energyRating"
    class="detail-energy">
    <a
      :href="energyRating._detail_energy_url"
      target="_blank"
      rel="me, nofollow noopener noreferrer"
      :class="['detail-energy__link', disabledUrl]">
      <img
        :src="energyRating._detail_energy"
        :alt="energyRating._detail_name"
        class="detail-energy__image" />
    </a>
  </div>
</template>
<script>
  import DISABLED_URL from 'CommonUtils/disabledUrl';

  export default {
    name: 'EnergyRating',
    props: {
      energyRating: {
        type: Object,
        required: true
      },
    },
    setup(props){
      const disabledUrl = computed(()=>{
        if (props.energyRating._detail_energy_url === DISABLED_URL.VOID){
          return 'disabled-click';
        }
       return '';

      })
      return { disabledUrl }
    }
  };
</script>
<style scoped>
  .disabled-click {
    pointer-events: none;
  }
</style>
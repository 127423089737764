/**
 * @description: There are two types of resolutions on big scale:
 * resolutions: resolutions to actually use on the HTML
 * preload resolutions: resolutions to use on head of the HTML to use preload browser directive.
 * preload resolutions should have the min-width and max-width, to work properly 
 * Should be a match between PDP_RESOLUTIONS and PDP_RESOLUTIONS_PRELOAD,
 * the same with PLP_RESOLUTIONS and PLP_RESOLUTIONS_PRELOAD
 * More information in https://web.dev/articles/preload-responsive-images
 */

module.exports = {
  IMPOLICY_RULES: {
    RESIZE: 'Resize',
  },
  PDP_RESOLUTIONS: [
    { media: '(max-width: 425px)', size: 720 },
    { media: '(max-width: 1440px)', size: 900 },
    { media: '(max-width: 1920px)', size: 1200 },
    { media: '(min-width: 1921px)', size: 1200 },
  ],
  PLP_RESOLUTIONS:{
    big_products: [
      { media: '(max-width: 425px)', size: 720 },
      { media: '(max-width: 768px)', size: 820 },
      { media: '(max-width: 1440px)', size: 960 },
      { media: '(max-width: 1920px)', size: 1200 },
      { media: '(min-width: 1921px)', size: 1200 },
    ],
    little_products: [
      { media: '(max-width: 425px)', size: 520 },
      { media: '(max-width: 1440px)', size: 860 },
      { media: '(max-width: 1920px)', size: 1200 },
      { media: '(min-width: 1921px)', size: 1200 },
    ]
  },
  PDP_RESOLUTIONS_PRELOAD: [
    { media: '(max-width: 425px)', size: 720 },
    { media: '(min-width: 425.1px) and (max-width: 1440px)', size: 900 },
    { media: '(min-width: 1920.1px) and (max-width: 1920px)', size: 1200 },
    { media: '(min-width: 1921px)', size: 1200 },
  ],
  PLP_RESOLUTIONS_PRELOAD:{
    big_products: [
      { media: '(max-width: 425px)', size: 780 },
      { media: '(min-width: 425.1px) and (max-width: 768px)', size: 820 },
      { media: '(min-width: 768.1px) and (max-width: 1440px)', size: 960 },
      { media: '(min-width: 1440.1px) and (max-width: 1920px)', size: 1200 },
      { media: '(min-width: 1921px)', size: 1200 },
    ],
    little_products: [
      { media: '(max-width: 425px)', size: 520 },
      { media: '(min-width: 425.1px) and (max-width: 1440px)', size: 540 },
      { media: '(min-width: 1440.1px) and (max-width: 1920px)', size: 720 },
      { media: '(min-width: 1921px)', size: 1200 },
    ]
  },
  VIDEO_RESOLUTIONS: {
    small: {
      media: '(max-width: 425px)',
    },
    medium: {
      media: '(min-width: 425px) and (max-width: 1440px)',
    },
    big:{
      media: '(min-width: 1440px)',
    },
  }
}
